import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/layout"

export default function About() {
    return (
        <Layout>
            <Container fluid>
                <div className="container about-container">
                <h1 style={{ paddingTop: `2rem`, display: `flex`, justifyContent: `center` }}>About me</h1>
                <p style={{ paddingTop: `2rem`, display: `flex`, justifyContent: `center` }}>
                    Hi! I'm an application developer who works with Ignition Perspective daily. 
                    Over time, I have come to appreciate and love this platform for many reasons 
                    including unlimited licensing, cross-platform support, and an engaged community always willing to help. 
                    I have found that what I can build in Perspective is usually only limited by my own imagination. 
                    This site will contain resources that I hope will convey my enthusiasm and share my experiences working 
                    with Ignition Perspective.
                    <br></br>
                    <br></br>
                    I have no affiliation with Inductive Automation and any code snippets, modules, and projects distributed 
                    through this site are for informational and demonstrational purposes only (so please don't sue me!).
                </p>
                </div> 
            </Container>
            
        </Layout>
    );
}